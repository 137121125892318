/*
  Load global css here
*/

/* merriweather-regular */
@font-face {
  font-family: Merriweather;
  font-style: normal;
  font-weight: 400;
  src: local('Merriweather'),
    url('./assets/fonts/merriweather/merriweather-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/merriweather/merriweather-regular.woff')
      format('woff');

  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-sans-regular */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito Sans'),
    url('./assets/fonts/nunito-sans/nunito-sans-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/nunito-sans/nunito-sans-regular.woff') format('woff');

  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* gloock-regular */
@font-face {
  font-family: Gloock;
  font-style: normal;
  font-weight: 400;
  src: local('Gloock'),
    url('./assets/fonts/gloock/gloock-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/gloock/gloock-regular.woff') format('woff');

  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html {
  height: 100%;
}

body {
  overflow-x: hidden;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.not-clickable {
  pointer-events: none;
}
